@mixin padding($numero:1){
  padding-left  : $gutter * $numero;
  padding-right : $gutter * $numero;
}

@mixin noPaddingContainer(){
  > .#{$item} { @include padding(0) }
}

@mixin ratio($h,$v) {
  $width         : 100%;
  width          : $width;
  height         : 0;
  overflow       : hidden;
  padding-bottom : $width * ($v/$h);
}

@mixin clearfix {
  &:before,
  &:after {
    content : "";
    width   : 100%;
    display : table;
    clear   : both;
  }
}

@mixin toRight($width:auto,$margin-left:1em){
  float       : right;
  width       : $width;
  margin-left : $margin-left;
}

@mixin toLeft($width:auto,$margin-right:1em){
  float        : left;
  width        : $width;
  margin-right : $margin-right;
}

@mixin toCenter($width:auto){
  display      : table;
  width        : $width;
  margin-left  : auto;
  margin-right : auto;
  text-align   : center;
}

@mixin circle { border-radius : 50% }

@mixin clearfix(){
  &:before, &:after {
    content : "";
    display : table;
    width   : 100%;
    clear   : both;
  }
}

@mixin full(){
  width     : 100%;
  max-width : 100%;
  clear     : both;
}

@mixin stickyFooter {
  @at-root {
    body {
      display        : flex;
      flex-direction : column;
      min-height     : 100vh;
    }
  }

  margin-top : auto;
}

@mixin cssStyckyFooter {
  display        : flex;
  flex-direction : column;
  min-height     : 100vh;

  > footer { margin-top : auto }
}

// Versiones CSS
@mixin helpers {
  .to-center                { @include toCenter()           }
  .to-left                  { @include toLeft()             }
  .to-right                 { @include toRight()            }
  .full                     { @include full()               }
  .circle                   { @include circle()             }
  .clearfix                 { @include clearfix()           }
  .no-padding.#{$container} { @include noPaddingContainer() }
  .no-padding.#{$item}      { @include padding(0)           }
  .padding                  { @include padding(1)           }
  .padding-2                { @include padding(2)           }
  .padding-3                { @include padding(3)           }
  body.sticky-footer        { @include cssStyckyFooter()    }
}


.main-menu{
  @include edMenu(l);
  @include fromTo(1025px,1080px){
    transform: scale(.8);
    float: right;
  }
  @include fromTo(1081px,1161px){
    transform: scale(.9);
    float: right;
  }
  @include to(l){
    position: relative;
    top: $menu-height;
    background: rgba(#000,.75);
    border: $border;
    height: auto;
    overflow-y: auto;
  }
  li{
   background: transparent;
   transition: $transition;
   &:hover{
     @include to(l){
       background: rgba(darken($main-color,10),75);
     }
   }
   @include to(l){
     border-bottom: $border;
     &:last-child {
       border-bottom : none;
     }
   }
  }
  a{
    line-height: $menu-height;
    color: $second-color;
    text-transform: uppercase;
    font-size: .9em;
    @include to(l){
      line-height: $menu-height*.75;
    }
  }

 }
.main-nav{
  @include edNav(right,l);
}
.toggle-menu{
  @include navToggle(1.5em, rgba($second-color,.75));
  @include from(l){
    display: none;
  }
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  margin-right: $gutter;
}

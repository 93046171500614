@charset "UTF-8";

@font-face {
  font-family: "boinas-negras";
  src:url("../fonts/boinas-negras.eot");
  src:url("../fonts/boinas-negras.eot?#iefix") format("embedded-opentype"),
  url("../fonts/boinas-negras.woff") format("woff"),
  url("../fonts/boinas-negras.ttf") format("truetype"),
  url("../fonts/boinas-negras.svg#boinas-negras") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "boinas-negras" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "boinas-negras" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-chevron-left:before {
  content: "\61";
}
.icon-chevron-right:before {
  content: "\62";
}
.icon-chevron-up:before {
  content: "\63";
}
.icon-chevron-down:before {
  content: "\64";
}
.icon-down:before {
  content: "\65";
}
.icon-left:before {
  content: "\66";
}
.icon-right:before {
  content: "\67";
}
.icon-up:before {
  content: "\68";
}
.icon-facebook:before {
  content: "\69";
}
.icon-twitter:before {
  content: "\6a";
}
.icon-youtube:before {
  content: "\6b";
}
.icon-menu:before {
  content: "\6c";
}
.icon-close:before {
  content: "\6d";
}
.icon-times-circle-o:before {
  content: "\6e";
}
.icon-zoom:before {
  content: "\6f";
}
.icon-pic:before {
  content: "\70";
}
.icon-map:before {
  content: "\71";
}
.icon-resize:before {
  content: "\72";
}
.icon-arrows-alt:before {
  content: "\73";
}
.icon-pic1:before {
  content: "\74";
}
.icon-phone:before {
  content: "\75";
}
.icon-user:before {
  content: "\76";
}
.icon-email:before {
  content: "\77";
}
.icon-message:before {
  content: "\79";
}
.icon-users:before {
  content: "\78";
}
.icon-building:before {
  content: "\7a";
}
.icon-calendar:before {
  content: "\41";
}
.icon-skill:before {
  content: "\42";
}
.icon-school:before {
  content: "\43";
}
.icon-load:before {
  content: "\44";
}
.icon-loop:before {
  content: "\45";
}
.icon-load-c:before {
  content: "\46";
}
.icon-load-d:before {
  content: "\47";
}
.icon-load-b:before {
  content: "\48";
}

.main-footer{
  @include stickyFooter;
  margin-top: 2em;
  background: $main-color;
  height: 3em;
  display: flex;
  align-items: center;
  &__container{
    @include edContainer;

    p{
      @include edItem(100);
      text-align: center;
      color: $second-color;
    }
    span, a{
      margin: 0 5px;
    }
    a{
      color: inherit;
      &:hover{
        text-decoration:underline;
      }
      &.dev{
        font-weight: bold;
      }
    }
  }
}

.main-header{
  position: fixed;
  width: 100%;
  background: rgba(#222,.9);
  z-index: 10000;
  border-bottom: 1px solid rgba($second-color,.3);
  transition: $transition;
  max-height: $menu-height;
  &__container{
    height: $menu-height;
  }
  &__logo{
    @include to(l){
      padding-left: $gutter;
    }
    h1{
      color: darken($gold,10);
      text-transform: uppercase;
      text-shadow: texto3d(3,rgba(darken($gold,30),.4));
      span{
        font-size: .9em;
        font-weight: bolder;
      }

      @include fromTo(1025px,1161px){
        transform: scale(.7);
      }
    }
  }
}
.black{
  background: #222 !important;
}

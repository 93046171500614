// RWD Bases
*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  margin      : 0;
  font-family : sans-serif;
}

// Imágenes responsive
img {
  display: block;
  width : 100%;
  height    : auto;
}

a {
  text-decoration : none;

  &:hover { text-decoration : underline }
}

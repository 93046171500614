@import 'https://fonts.googleapis.com/css?family=Josefin+Sans:400,700';
@import 'https://fonts.googleapis.com/css?family=Varela+Round';
body {
  display: flex;
  flex-direction: column; }

@font-face {
  font-family: 'FontAwesome';
  src: url("../fonts/fontawesome-webfont.eot?v=4.6.3");
  src: url("../fonts/fontawesome-webfont.eot?#iefix&v=4.6.3") format("embedded-opentype"), url("../fonts/fontawesome-webfont.woff2?v=4.6.3") format("woff2"), url("../fonts/fontawesome-webfont.woff?v=4.6.3") format("woff"), url("../fonts/fontawesome-webfont.ttf?v=4.6.3") format("truetype"), url("../fonts/fontawesome-webfont.svg?v=4.6.3#fontawesomeregular") format("svg");
  font-weight: normal;
  font-style: normal; }

.fa {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.fa-spin {
  -webkit-animation: fa-spin 2s infinite linear;
  animation: fa-spin 2s infinite linear; }

.fa-pulse {
  -webkit-animation: fa-spin 1s infinite steps(8);
  animation: fa-spin 1s infinite steps(8); }

@-webkit-keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg); } }

@keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg); } }

.fa-spinner:before {
  content: "\f110"; }

.section-content ul, .section-content ol {
  list-style: none;
  padding-left: 0;
  margin-left: 0; }

@font-face {
  font-family: "boinas-negras";
  src: url("../fonts/boinas-negras.eot");
  src: url("../fonts/boinas-negras.eot?#iefix") format("embedded-opentype"), url("../fonts/boinas-negras.woff") format("woff"), url("../fonts/boinas-negras.ttf") format("truetype"), url("../fonts/boinas-negras.svg#boinas-negras") format("svg");
  font-weight: normal;
  font-style: normal; }

[data-icon]:before {
  font-family: "boinas-negras" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "boinas-negras" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-chevron-left:before {
  content: "\61"; }

.icon-chevron-right:before {
  content: "\62"; }

.icon-chevron-up:before {
  content: "\63"; }

.icon-chevron-down:before {
  content: "\64"; }

.icon-down:before {
  content: "\65"; }

.icon-left:before {
  content: "\66"; }

.icon-right:before {
  content: "\67"; }

.icon-up:before {
  content: "\68"; }

.icon-facebook:before {
  content: "\69"; }

.icon-twitter:before {
  content: "\6a"; }

.icon-youtube:before {
  content: "\6b"; }

.icon-menu:before {
  content: "\6c"; }

.icon-close:before {
  content: "\6d"; }

.icon-times-circle-o:before {
  content: "\6e"; }

.icon-zoom:before {
  content: "\6f"; }

.icon-pic:before {
  content: "\70"; }

.icon-map:before {
  content: "\71"; }

.icon-resize:before {
  content: "\72"; }

.icon-arrows-alt:before {
  content: "\73"; }

.icon-pic1:before {
  content: "\74"; }

.icon-phone:before {
  content: "\75"; }

.icon-user:before {
  content: "\76"; }

.icon-email:before {
  content: "\77"; }

.icon-message:before {
  content: "\79"; }

.icon-users:before {
  content: "\78"; }

.icon-building:before {
  content: "\7a"; }

.icon-calendar:before {
  content: "\41"; }

.icon-skill:before {
  content: "\42"; }

.icon-school:before {
  content: "\43"; }

.icon-load:before {
  content: "\44"; }

.icon-loop:before {
  content: "\45"; }

.icon-load-c:before {
  content: "\46"; }

.icon-load-d:before {
  content: "\47"; }

.icon-load-b:before {
  content: "\48"; }

*,
*:before,
*:after {
  box-sizing: border-box; }

body {
  margin: 0;
  font-family: sans-serif; }

img {
  display: block;
  width: 100%;
  height: auto; }

a {
  text-decoration: none; }
  a:hover {
    text-decoration: underline; }

.ed-container, .main-footer__container, .contacto__container {
  display: flex;
  flex-wrap: wrap;
  max-width: 75rem;
  margin-left: auto;
  margin-right: auto;
  width: 100%; }

.ed-item, .main-footer__container p, .contacto__datos, .contacto__form {
  margin: 0;
  padding-left: 0.9375em;
  padding-right: 0.9375em; }

.ed-item {
  width: auto; }
  .ed-item.ed-container {
    padding-left: 0;
    padding-right: 0; }

.button, .form-footer .btn {
  display: inline-block;
  line-height: 2.5em;
  padding: 0 1.5em;
  cursor: pointer;
  margin-bottom: 1em;
  border: none; }

.s-5 {
  width: 5%; }

.s-10 {
  width: 10%; }

.s-15 {
  width: 15%; }

.s-20 {
  width: 20%; }

.s-25 {
  width: 25%; }

.s-30 {
  width: 30%; }

.s-35 {
  width: 35%; }

.s-40 {
  width: 40%; }

.s-45 {
  width: 45%; }

.s-50 {
  width: 50%; }

.s-55 {
  width: 55%; }

.s-60 {
  width: 60%; }

.s-65 {
  width: 65%; }

.s-70 {
  width: 70%; }

.s-75 {
  width: 75%; }

.s-80 {
  width: 80%; }

.s-85 {
  width: 85%; }

.s-90 {
  width: 90%; }

.s-95 {
  width: 95%; }

.s-100 {
  width: 100%; }

.s-1-3 {
  width: 33.33333%; }

.s-2-3 {
  width: 66.66667%; }

.s-3-3 {
  width: 100%; }

.s-1-6 {
  width: 16.66667%; }

.s-2-6 {
  width: 33.33333%; }

.s-3-6 {
  width: 50%; }

.s-4-6 {
  width: 66.66667%; }

.s-5-6 {
  width: 83.33333%; }

.s-6-6 {
  width: 100%; }

@media all and (min-width: 40em) {
  .m-5 {
    width: 5%; }
  .m-10 {
    width: 10%; }
  .m-15 {
    width: 15%; }
  .m-20 {
    width: 20%; }
  .m-25 {
    width: 25%; }
  .m-30 {
    width: 30%; }
  .m-35 {
    width: 35%; }
  .m-40 {
    width: 40%; }
  .m-45 {
    width: 45%; }
  .m-50 {
    width: 50%; }
  .m-55 {
    width: 55%; }
  .m-60 {
    width: 60%; }
  .m-65 {
    width: 65%; }
  .m-70 {
    width: 70%; }
  .m-75 {
    width: 75%; }
  .m-80 {
    width: 80%; }
  .m-85 {
    width: 85%; }
  .m-90 {
    width: 90%; }
  .m-95 {
    width: 95%; }
  .m-100 {
    width: 100%; }
  .m-1-3 {
    width: 33.33333%; }
  .m-2-3 {
    width: 66.66667%; }
  .m-3-3 {
    width: 100%; }
  .m-1-6 {
    width: 16.66667%; }
  .m-2-6 {
    width: 33.33333%; }
  .m-3-6 {
    width: 50%; }
  .m-4-6 {
    width: 66.66667%; }
  .m-5-6 {
    width: 83.33333%; }
  .m-6-6 {
    width: 100%; } }

@media all and (min-width: 64.0625em) {
  .l-5 {
    width: 5%; }
  .l-10 {
    width: 10%; }
  .l-15 {
    width: 15%; }
  .l-20 {
    width: 20%; }
  .l-25 {
    width: 25%; }
  .l-30 {
    width: 30%; }
  .l-35 {
    width: 35%; }
  .l-40 {
    width: 40%; }
  .l-45 {
    width: 45%; }
  .l-50 {
    width: 50%; }
  .l-55 {
    width: 55%; }
  .l-60 {
    width: 60%; }
  .l-65 {
    width: 65%; }
  .l-70 {
    width: 70%; }
  .l-75 {
    width: 75%; }
  .l-80 {
    width: 80%; }
  .l-85 {
    width: 85%; }
  .l-90 {
    width: 90%; }
  .l-95 {
    width: 95%; }
  .l-100 {
    width: 100%; }
  .l-1-3 {
    width: 33.33333%; }
  .l-2-3 {
    width: 66.66667%; }
  .l-3-3 {
    width: 100%; }
  .l-1-6 {
    width: 16.66667%; }
  .l-2-6 {
    width: 33.33333%; }
  .l-3-6 {
    width: 50%; }
  .l-4-6 {
    width: 66.66667%; }
  .l-5-6 {
    width: 83.33333%; }
  .l-6-6 {
    width: 100%; } }

@media all and (min-width: 90em) {
  .xl-5 {
    width: 5%; }
  .xl-10 {
    width: 10%; }
  .xl-15 {
    width: 15%; }
  .xl-20 {
    width: 20%; }
  .xl-25 {
    width: 25%; }
  .xl-30 {
    width: 30%; }
  .xl-35 {
    width: 35%; }
  .xl-40 {
    width: 40%; }
  .xl-45 {
    width: 45%; }
  .xl-50 {
    width: 50%; }
  .xl-55 {
    width: 55%; }
  .xl-60 {
    width: 60%; }
  .xl-65 {
    width: 65%; }
  .xl-70 {
    width: 70%; }
  .xl-75 {
    width: 75%; }
  .xl-80 {
    width: 80%; }
  .xl-85 {
    width: 85%; }
  .xl-90 {
    width: 90%; }
  .xl-95 {
    width: 95%; }
  .xl-100 {
    width: 100%; }
  .xl-1-3 {
    width: 33.33333%; }
  .xl-2-3 {
    width: 66.66667%; }
  .xl-3-3 {
    width: 100%; }
  .xl-1-6 {
    width: 16.66667%; }
  .xl-2-6 {
    width: 33.33333%; }
  .xl-3-6 {
    width: 50%; }
  .xl-4-6 {
    width: 66.66667%; }
  .xl-5-6 {
    width: 83.33333%; }
  .xl-6-6 {
    width: 100%; } }

.to-center {
  display: table;
  width: auto;
  margin-left: auto;
  margin-right: auto;
  text-align: center; }

.to-left {
  float: left;
  width: auto;
  margin-right: 1em; }

.to-right {
  float: right;
  width: auto;
  margin-left: 1em; }

.full {
  max-width: 100%; }

.circle {
  border-radius: 50%; }

.clearfix:before, .clearfix:after {
  content: "";
  display: table;
  width: 100%;
  clear: both; }

.no-padding.ed-container > .ed-item {
  padding-left: 0em;
  padding-right: 0em; }

.no-padding.ed-item {
  padding-left: 0em;
  padding-right: 0em; }

.padding {
  padding-left: 0.9375em;
  padding-right: 0.9375em; }

.padding-2 {
  padding-left: 1.875em;
  padding-right: 1.875em; }

.padding-3 {
  padding-left: 2.8125em;
  padding-right: 2.8125em; }

body.sticky-footer {
  display: flex;
  flex-direction: column;
  min-height: 100vh; }
  body.sticky-footer > footer {
    margin-top: auto; }

.main-justify {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }

.main-distribute {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around; }

.main-center {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; }

.main-start {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }

.main-end {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end; }

.cross-start {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: flex-start; }

.cross-center {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: center; }

.cross-end {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  align-content: flex-end; }

.flex-reverse {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row-reverse; }

.flex-column {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column; }

.flex-column-reverse {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column-reverse; }

.abcenter {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center; }

.from-s {
  display: none; }

.to-s {
  display: none; }

@media screen and (max-width: 40em) {
  .from-m {
    display: none; } }

@media screen and (min-width: 40em) {
  .to-m {
    display: none; } }

@media screen and (max-width: 64.0625em) {
  .from-l {
    display: none; } }

@media screen and (min-width: 64.0625em) {
  .to-l {
    display: none; } }

@media screen and (max-width: 90em) {
  .from-xl {
    display: none; } }

@media screen and (min-width: 90em) {
  .to-xl {
    display: none; } }

.ed-video {
  height: 0;
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative; }
  .ed-video > iframe,
  .ed-video > video > .video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.button {
  background: #ddd;
  color: #333; }
  .button:hover {
    background: #d0d0d0;
    text-decoration: none; }
  .button:active {
    transform: scale(0.97); }

.vmodal {
  position: fixed;
  height: 100vh;
  width: 100%;
  background: rgba(0, 0, 0, 0.85);
  z-index: 100000;
  top: 0;
  left: 0;
  display: none; }
  .vmodal__container {
    border: 3px solid #000;
    width: 90%;
    max-width: 780px;
    margin: 0 auto;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    padding: 0.3125rem;
    padding-bottom: 0;
    background: #fff;
    max-height: 90%; }
    .vmodal__container > img {
      display: inline-block;
      width: 100%;
      margin: 0 auto; }
  .vmodal__close {
    display: block;
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
    transform: translateY(-50%) translateX(50%);
    width: 1.5em;
    height: 1.5em;
    line-height: 1.5em;
    font-size: 1.3em;
    background: #fff;
    color: #000;
    text-align: center;
    border-radius: 50%;
    border: 2px solid #000; }
    .vmodal__close:before {
      top: 0; }
  .vmodal iframe {
    width: 100%;
    height: 400px;
    border: none;
    overflow: hidden; }

body {
  background: url("../img/bg.jpg") center center/cover fixed;
  font-family: "Varela Round", sans-serif;
  color: #4d4d4d;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh; }

a {
  color: initial;
  text-decoration: none; }
  a:hover {
    text-decoration: none; }

p, blockquote, li {
  font-size: 1em;
  line-height: 1.5; }

p {
  margin: 0 0 0 0; }
  p:last-child, p:only-child {
    margin: 0; }

h1, h2, h3, h4, h5, h6 {
  font-family: "Josefin Sans", sans-serif;
  font-weight: 700;
  text-transform: normal;
  color: #4d4d4d;
  margin: 0;
  display: block; }

h1 {
  font-size: 2em;
  line-height: 1.2; }

h2 {
  font-size: 1.6em;
  width: 100%; }

h3 {
  font-size: 1.3em;
  width: 100%; }

h4 {
  font-size: 1em; }

h5 {
  font-size: 0.9em; }

h6 {
  font-size: 0.8em; }

nav {
  margin: 0;
  padding: 0; }

section {
  padding-top: 7em; }

.main-menu {
  width: 100%; }
  .main-menu, .main-menu ul {
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 0;
    list-style: none; }
  .main-menu li {
    position: relative;
    list-style: none;
    flex: auto; }
    .main-menu li.parent-submenu {
      display: flex;
      flex-wrap: wrap; }
      .main-menu li.parent-submenu a {
        flex: auto; }
  .main-menu ul {
    display: none; }
    .main-menu ul.show-submenu {
      display: block;
      width: 100%;
      order: 3;
      margin-left: 1em;
      margin-bottom: .5em; }
  .main-menu a {
    display: block;
    line-height: 3em;
    padding: 0 1em; }
    .main-menu a:hover {
      text-decoration: none; }
  @media screen and (min-width: 64.0625em) {
    .main-menu {
      display: flex;
      align-items: center; }
      .main-menu a {
        text-align: center; }
      .main-menu .expand {
        display: none; }
      .main-menu ul {
        position: absolute;
        top: 100%;
        min-width: 100%;
        white-space: nowrap; }
        .main-menu ul a {
          text-align: left; }
        .main-menu ul ul {
          top: 0;
          left: 100%; }
      .main-menu li:hover > ul {
        display: block; } }
  .main-menu .expand-submenu {
    position: relative;
    right: 0;
    width: 3em;
    height: 3em;
    cursor: pointer;
    transition: all .3s; }
    @media screen and (min-width: 64.0625em) {
      .main-menu .expand-submenu {
        display: none; } }
    .main-menu .expand-submenu::after {
      content: "";
      position: absolute;
      width: 40%;
      height: 40%;
      top: 20%;
      left: 30%;
      border-left: 0.45em solid #666;
      border-bottom: 0.45em solid #666;
      border-radius: 0.24em;
      transform: rotate(-45deg);
      transition: all .3s; }
    .main-menu .expand-submenu.active {
      transform: rotate(180deg); }
  @media screen and (min-width: 1025px) and (max-width: 1080px) {
    .main-menu {
      transform: scale(0.8);
      float: right; } }
  @media screen and (min-width: 1081px) and (max-width: 1161px) {
    .main-menu {
      transform: scale(0.9);
      float: right; } }
  @media screen and (max-width: 64.0625em) {
    .main-menu {
      position: relative;
      top: 4rem;
      background: rgba(0, 0, 0, 0.75);
      border: 1px solid rgba(255, 255, 255, 0.1);
      height: auto;
      overflow-y: auto; } }
  .main-menu li {
    background: transparent;
    transition: all 0.3s; }
    @media screen and (max-width: 64.0625em) {
      .main-menu li:hover {
        background: #732626; } }
    @media screen and (max-width: 64.0625em) {
      .main-menu li {
        border-bottom: 1px solid rgba(255, 255, 255, 0.1); }
        .main-menu li:last-child {
          border-bottom: none; } }
  .main-menu a {
    line-height: 4rem;
    color: #FDFDFD;
    text-transform: uppercase;
    font-size: .9em; }
    @media screen and (max-width: 64.0625em) {
      .main-menu a {
        line-height: 3rem; } }

@media screen and (max-width: 64.0625em) {
  .main-nav {
    position: fixed;
    transition: all .3s;
    height: 100vh;
    z-index: 200;
    width: 85%;
    right: -85%; }
    .main-nav.show-menu {
      right: 0; } }

.toggle-menu {
  position: relative;
  z-index: 300;
  width: 1.95em;
  height: 1.5em;
  box-shadow: 0 0.375em rgba(253, 253, 253, 0.75) inset;
  cursor: pointer;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  margin-right: 0.9375em; }
  .toggle-menu::after, .toggle-menu::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 0.375em;
    background-color: rgba(253, 253, 253, 0.75);
    bottom: 0; }
  .toggle-menu::after {
    bottom: 0.5625em; }
  @media screen and (min-width: 64.0625em) {
    .toggle-menu {
      display: none; } }

.main-header {
  position: fixed;
  width: 100%;
  background: rgba(34, 34, 34, 0.9);
  z-index: 10000;
  border-bottom: 1px solid rgba(253, 253, 253, 0.3);
  transition: all 0.3s;
  max-height: 4rem; }
  .main-header__container {
    height: 4rem; }
  @media screen and (max-width: 64.0625em) {
    .main-header__logo {
      padding-left: 0.9375em; } }
  .main-header__logo h1 {
    color: #ccac00;
    text-transform: uppercase;
    text-shadow: 2px 2px rgba(102, 86, 0, 0.4), 1px 1px rgba(102, 86, 0, 0.4), 2px 2px rgba(102, 86, 0, 0.4), 3px 3px rgba(102, 86, 0, 0.4); }
    .main-header__logo h1 span {
      font-size: .9em;
      font-weight: bolder; }
    @media screen and (min-width: 1025px) and (max-width: 1161px) {
      .main-header__logo h1 {
        transform: scale(0.7); } }

.black {
  background: #222 !important; }

.main-footer {
  margin-top: auto;
  margin-top: 2em;
  background: #993333;
  height: 3em;
  display: flex;
  align-items: center; }
  body {
    display: flex;
    flex-direction: column;
    min-height: 100vh; }
  .main-footer__container p {
    width: 100%;
    text-align: center;
    color: #FDFDFD; }
  .main-footer__container span, .main-footer__container a {
    margin: 0 5px; }
  .main-footer__container a {
    color: inherit; }
    .main-footer__container a:hover {
      text-decoration: underline; }
    .main-footer__container a.dev {
      font-weight: bold; }

.inicio {
  position: relative;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url(../img/banner.jpg) center center/cover fixed no-repeat; }
  .inicio__logo {
    width: 60%;
    max-width: 250px;
    background: #FDFDFD;
    padding: 0.9375em;
    border-radius: 50%; }
    @media all and (max-width: 640px) and (orientation: landscape) {
      .inicio__logo {
        max-width: 180px; } }

.nosotros {
  min-height: 100vh; }

.nosotros > .section-content {
  position: relative; }
  .nosotros > .section-content > h3 {
    margin-top: 1em; }

.cedulas-contaner {
  display: flex;
  flex-wrap: wrap;
  margin-left: -1em;
  margin-right: -1em; }
  .cedulas-contaner > .cedulas-item {
    width: calc(100% - 2em);
    margin: 1em; }
  @media screen and (min-width: 40em) {
    .cedulas-contaner > .cedulas-item {
      width: calc(50% - 2em); } }
  @media screen and (min-width: 64.0625em) {
    .cedulas-contaner {
      max-width: 80%;
      margin: 0 auto; } }

.avisopriv {
  position: absolute;
  top: .5em;
  right: .5em;
  line-height: 2em;
  padding: 0 1em;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 1em;
  transition: all .3s;
  color: rgba(255, 255, 255, 0.8); }
  .avisopriv:hover {
    background: rgba(255, 255, 255, 0.4);
    color: rgba(68, 68, 68, 0.8); }

.modal-iframe .vmodal__container {
  max-width: 90% !important;
  height: 90% !important; }

.modal-iframe iframe {
  height: 98% !important; }

.galery-container {
  display: flex;
  flex-wrap: wrap;
  margin-left: -0.75em;
  margin-right: -0.75em; }
  .galery-container > .galery-item {
    width: calc(50% - 1.5em);
    margin: 0.75em; }
  @media screen and (min-width: 40em) {
    .galery-container > .galery-item {
      width: calc(33.33333% - 1.5em); } }
  @media screen and (min-width: 64.0625em) {
    .galery-container > .galery-item {
      width: calc(25% - 1.5em); } }

.galery-item {
  box-shadow: 0 0 0 1px #000, 0 0 0 3px #FDFDFD, 0 0 0 5px #000;
  overflow: hidden; }
  .galery-item a {
    display: block;
    position: relative; }
    .galery-item a::after {
      content: "\74";
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background: rgba(0, 0, 0, 0.5);
      color: #FDFDFD;
      font-family: "boinas-negras";
      font-size: 1.5em;
      transition: all 0.3s;
      opacity: 0;
      transform: scale(0); }
    .galery-item a img {
      transition: all 0.3s; }
    .galery-item a:hover::after {
      transform: scale(1);
      opacity: 1; }
    .galery-item a:hover img {
      transform: scale(1.3) rotate(-10deg); }

.contacto__datos {
  width: 100%; }
  @media screen and (min-width: 40em) {
    .contacto__datos {
      width: 50%; } }
  .contacto__datos h3, .contacto__datos h4, .contacto__datos p {
    text-align: right; }
  .contacto__datos h3 {
    font-size: 3em; }
  .contacto__datos h4 {
    font-size: 1.5em; }
  .contacto__datos .p-group {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 0.46875em; }
    .contacto__datos .p-group:first-of-type {
      margin-top: 0.9375em; }
  .contacto__datos span {
    padding-left: 8px; }
  .contacto__datos [class^="icon-"]:before {
    position: relative;
    top: 2px; }
  .contacto__datos .icon-map::before {
    top: 5px; }
  .contacto__datos a {
    color: inherit; }
    .contacto__datos a:hover {
      text-decoration: underline; }

.contacto .mapa a {
  display: block;
  box-shadow: 0 0 0 2px rgba(153, 51, 51, 0.5);
  transition: all 0.3s;
  position: relative; }
  .contacto .mapa a::after {
    content: '\72';
    font-family: "boinas-negras" !important;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.75);
    opacity: 0;
    transition: all 0.3s;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5em;
    color: #fff; }
  .contacto .mapa a:hover {
    box-shadow: 0 0 0 2px #993333; }
    .contacto .mapa a:hover:after {
      opacity: 1; }

.contacto__form {
  width: 100%;
  margin-top: 2em; }
  @media screen and (min-width: 40em) {
    .contacto__form {
      width: 50%; } }
  @media screen and (min-width: 40em) {
    .contacto__form {
      margin-top: 0.9375em;
      padding-left: 0.9375em;
      padding-right: 0.9375em; } }
  .contacto__form .select-group {
    position: relative;
    background: #FDFDFD; }
    .contacto__form .select-group label {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 100;
      width: 1.875rem;
      height: 100%;
      font-size: 1.5em;
      display: flex;
      justify-content: center;
      align-items: center; }
    .contacto__form .select-group select {
      display: block;
      height: 2.125rem;
      width: 100%;
      appearance: none;
      border: 1px solid #993333;
      padding-left: 0.4375em;
      margin-bottom: 1.875em;
      position: relative;
      background: transparent;
      z-index: 500; }
      .contacto__form .select-group select:focus {
        outline: none;
        border: 1px solid #993333; }

.form {
  margin-top: 0.9375em;
  padding: 0.9375em;
  padding-top: 1.875em;
  background: rgba(253, 253, 253, 0.25);
  border: 1px solid #993333; }
  .form .input-group, .form .input-group-textarea {
    position: relative;
    margin-bottom: 2.34375em; }
    .form .input-group span, .form .input-group-textarea span {
      display: none; }
    .form .input-group label, .form .input-group input, .form .input-group-textarea label, .form .input-group-textarea input {
      height: 2rem; }
    .form .input-group label, .form .input-group-textarea label {
      position: absolute;
      left: 5px;
      top: 5px;
      transition: all 0.3s;
      color: #993333; }
    .form .input-group input, .form .input-group textarea, .form .input-group-textarea input, .form .input-group-textarea textarea {
      background: transparent;
      width: 100%;
      border: none;
      outline: none;
      resize: none;
      border-bottom: 1px solid #993333;
      font-family: "Varela Round", sans-serif;
      font-size: 1em; }
      .form .input-group input:focus, .form .input-group textarea:focus, .form .input-group-textarea input:focus, .form .input-group-textarea textarea:focus {
        outline: none; }
        .form .input-group input:focus + label, .form .input-group textarea:focus + label, .form .input-group-textarea input:focus + label, .form .input-group-textarea textarea:focus + label {
          top: -1.5em;
          font-size: .85em;
          left: 0; }
    .form .input-group textarea, .form .input-group-textarea textarea {
      transition: all 0.3s;
      min-height: 2rem; }
      .form .input-group textarea + label, .form .input-group-textarea textarea + label {
        top: 7px; }
      .form .input-group textarea:focus, .form .input-group-textarea textarea:focus {
        min-height: 4rem; }
  .form .input-group-textarea {
    margin-bottom: 0.9375em; }
  .form .input-group.lleno label {
    top: -1.5em;
    font-size: .85em;
    left: 0; }
  .form .input-group-textarea.lleno label {
    top: -1.5em;
    font-size: .85em;
    left: 0; }
  .form .input-group-textarea.lleno textarea {
    min-height: 4rem; }

.form-footer {
  display: flex; }
  .form-footer .boton {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 25%; }
  .form-footer .messages {
    width: 75%; }
  .form-footer .btn {
    background: #993333;
    color: #333;
    color: #FDFDFD;
    text-transform: uppercase; }
    .form-footer .btn:hover {
      background: #862d2d;
      text-decoration: none; }
    .form-footer .btn:active {
      transform: scale(0.97); }
    @media screen and (max-width: 40em) {
      .form-footer .btn {
        padding: 0 10px; } }

.success, .error {
  padding: 0.3125rem;
  color: rgba(253, 253, 253, 0.5);
  display: none;
  text-align: center; }

.success {
  background: darkgreen; }

.error {
  background: darkred; }

.radio-group {
  margin-top: 0.9375em;
  margin-bottom: 1.875em;
  display: flex;
  justify-content: space-around; }
  .radio-group .radios {
    display: flex;
    justify-content: space-around;
    width: 60%;
    margin: 0 auto;
    padding-top: 1px; }
    .radio-group .radios input {
      outline: none; }
      .radio-group .radios input:focus {
        outline: none; }
  .radio-group p {
    color: #993333; }

#form-reclutamiento, #form-cotizacion {
  display: none; }

.network {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0.9375em; }
  .network a {
    width: 3.125rem;
    background: transparent;
    color: #4d4d4d;
    margin-right: 0.9375em;
    font-size: 1.7em;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    padding: 5px;
    transition: all 0.3s; }
    .network a:nth-child(3) {
      margin-right: 0; }
    .network a:hover {
      background: rgba(153, 51, 51, 0.95);
      text-decoration: none;
      color: #FDFDFD; }

.icon-load-b {
  animation: girar 3s linear infinite;
  display: inline-block;
  position: relative;
  color: yellow;
  font-size: .5em; }

@keyframes girar {
  to {
    transform: rotate(360deg);
    transform-origin: top; } }

.fa-spinner:before {
  padding: 0 1.5em; }

.accestomail {
  margin-top: 0.9375em;
  float: right; }

.section-title {
  height: 15.625rem;
  text-align: right;
  font-size: 3em;
  padding-right: 4rem;
  padding-top: 1rem;
  background: #222;
  color: #FDFDFD; }
  @media screen and (max-width: 40em) {
    .section-title {
      padding-right: 1rem; } }

.section-content {
  background: #993333;
  padding: 2rem;
  position: relative;
  top: -7.8125rem; }
  @media screen and (max-width: 40em) {
    .section-content {
      max-width: 90%;
      margin: 0 auto; } }
  .section-content h3 {
    text-align: center;
    text-transform: uppercase;
    color: #FDFDFD;
    margin-bottom: 0.9375em; }
  .section-content ul, .section-content ol {
    color: #FDFDFD;
    max-width: 90%;
    margin: 0 auto; }
    @media screen and (min-width: 64.0625em) {
      .section-content ul, .section-content ol {
        max-width: 70%; } }
    .section-content ul li, .section-content ol li {
      margin-bottom: 0.46875em;
      position: relative;
      text-align: center;
      padding: 5px; }
  .section-content p {
    color: #FDFDFD;
    text-align: center;
    margin-bottom: 0.9375em; }

.galeria{
}
.galery-container {
  @include grid('.galery-item',2,3,4,4,1.5em);
}
.galery-item{
  box-shadow:
    0 0 0 1px #000,
    0 0 0 3px $second-color,
    0 0 0 5px #000;
  overflow: hidden;
  a{
    display: block;
    position: relative;
    &::after{
      content: "\74";
      position: absolute;
      top: 0;
      left: 0;

      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background: rgba(#000,.5);
      color: $second-color;
      font-family: "boinas-negras";
      font-size: 1.5em;
      transition: $transition;
      opacity: 0;
      transform: scale(0);
    }
    img{
      transition: $transition;
    }
    &:hover{
      &::after{
        transform: scale(1);
        opacity: 1;
      }
      img{
        transform: scale(1.3) rotate(-10deg);
      }
    }
  }

}

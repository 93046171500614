body {
  //background  : $body--background;
  background: url("../img/bg.jpg") center center / cover fixed;
  font-family : $body--font-family;
  color       : $body--color;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
}

a {
  color           : $link--color;
  text-decoration : none;
  &:hover{
    text-decoration: none;
  }
}

p, blockquote, li {
  font-size   : $p--font-size;
  line-height : $p--line-height;
}

p {
  margin : 0 0 0 0;
  &:last-child,
  &:only-child {
    margin: 0;
  }
}

blockquote {

}

// Estilos básicos de los encabezados
h1, h2, h3, h4, h5, h6 {
  font-family    : $header--font-family;
  font-weight    : $header--font-weight;
  text-transform : $header--text-transform;
  color          : $header--color;
  margin: 0;
  display: block;
}

// Tamaños y estilos de los encabezados:
h1 {
  font-size : $h1--font-size;
  line-height: 1.2;
}

h2 {
  font-size : $h2--font-size;
  width: 100%;
}

h3 {
  font-size : $h3--font-size;
  width: 100%;
}

h4 {
  font-size : $h4--font-size;
}

h5 {
  font-size : $h5--font-size;
}

h6 {
  font-size : $h6--font-size;
}
nav{
  margin: 0;
  padding: 0;
}
section{
  padding-top: 7em;
  //min-height: 100vh;
}

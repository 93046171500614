.nosotros{
  min-height: 100vh;

}
.nosotros > .section-content{
  position: relative;
  & > h3{
    margin-top: 1em;
  }
}
.cedulas-contaner {
  @include grid('.cedulas-item',1,2,2,2,2em);
  @include from(l){
    max-width: 80%;
    margin: 0 auto;
  }
}
.avisopriv{
  position: absolute;
  top: .5em;
  right: .5em;
  line-height: 2em;
  padding: 0 1em;
  background: rgba(#fff,.1);
  border-radius: 1em;
  transition: all .3s;
  color: rgba(#fff,.8);
  &:hover{
    background: rgba(#fff,.4);
    color: rgba(#444,.8);
  }
}

.modal-iframe{
  & .vmodal__container{
    //width: 90%;
    max-width: 90% !important;
    height: 90% !important;
  }
  & iframe{
    height: 98% !important;
  }
}

.vmodal{
  position: fixed;
  height: 100vh;
  width: 100%;
  background: rgba(#000,.85);
  z-index: 100000;
  top:0;
  left: 0;
  display: none;
  &__container{
    border: 3px solid #000;
    width: 90%;
    max-width: 780px;
    margin: 0 auto;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    padding: rem(5px);
    padding-bottom: 0;
    background: #fff;
    max-height: 90%;
    & > img{
      display: inline-block;
      width: 100%;
      margin: 0 auto;
    }
  }
  &__close{
    display: block;
    $sice: 1.5em;
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
    transform: translateY(-50%) translateX(50%);
    width: $sice;
    height: $sice;
    line-height: $sice;
    font-size: 1.3em;
    background: #fff;
    color: #000;
    text-align: center;
    border-radius: 50%;
    border: 2px solid #000;
    &:before{
      top: 0;
    }
  }
  iframe{
    width: 100%;
    height: 400px;
    border: none;
    overflow: hidden;
  }
}

.inicio{
  position: relative;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background:
    //url(../img/dott.png) left top / 2px fixed repeat,
    //radial-gradient(
        //rgba(#000,.1),
        //rgba(#000,.2) 50%) fixed,
    url(../img/banner.jpg) center center / cover fixed no-repeat;
  &__logo{
    width:60%;
    max-width: 250px;
    background: $second-color;
    padding: $gutter;
    border-radius: 50%;
    @include orientationTo(640px,landscape){
      max-width: 180px;
    }
  }
}

// estilo default del menu
@mixin menuStyleDefault {
  background : #eee;
  li:hover { background : rgba(#000, .1)  }
  ul       { background : darken(#eee, 5) }
  a        { color      : #666            }
}

// estilo nav para el menu
// incluye el breakpoint que lo cambia a horizontal
@mixin menuStyleNav($bp) {
  $border    : 1px solid rgba(#000, .1);
  background : #EEE;
  border     : $border;

  li:hover { background : rgba(#000, .1)  }
  ul       { background : darken(#eee, 5) }
  a        { color      : #666            }

  li {
    border-bottom : $border;

    &:last-child {
      border-right  : none;
      border-bottom : none;
    }
  }

  ul {
    border : $border;

    li {
      border-bottom : $border;

      &:last-child { border-bottom : none }
    }
  }

  @include from($bp) {
    > li {
      border-right  : 1px solid rgba(#000, .1);
      border-bottom : none;
    }
  }
}

// estilo button para el menú
@mixin menuSyleButton {
  li {
    margin-bottom : .5em;
    margin-right  : .5em;
  }

  a {
    border        : 1px solid rgba(#000, .1);
    background    : #EEE;
    line-height   : 2.5em;
    padding       : 0 1.5em;
    border-radius : 4px;
    color         : #666;

    &:hover { background : rgba(#000, .1) }
  }
}

// mixin que construye el menú sin un tema, solo maqueta
// recibe el breakpoint en que se vuelve horizontal
// y el estilo (opcional): default|nav|button
@mixin edMenu($bp,$style:null) {
  width : 100%;

  &, ul {
    margin-top    : 0;
    margin-bottom : 0;
    padding-left  : 0;
    list-style    : none;
  }

  li {
    position   : relative;
    list-style : none;
    flex       : auto;

    // Si es padre de un submenu
    &.parent-submenu {
      display : flex;
      flex-wrap: wrap;

      a { flex: auto }
    }
  }

  //submenus
  ul {
    display  : none;

    &.show-submenu {
      display : block;
      width : 100%;
      order: 3;
      margin-left : 1em;
      margin-bottom : .5em;
    }
  }

  a {
    display     : block;
    line-height : 3em;
    padding     : 0 1em;

    &:hover { text-decoration : none }
  }

  // cambio a horizontal
  @include from($bp) {
    display     : flex;
    align-items : center;

    a { text-align : center }

    // Ocultar el botón de expandir en horizontal
    .expand { display : none}

    ul {
      position    : absolute;
      top         : 100%;
      min-width   : 100%;
      white-space : nowrap;

      a { text-align : left }

      ul {
        top  : 0;
        left : 100%;
      }
    }

    // mostrar submenu
    li:hover > ul { display : block }
  }

  // expand submenu button
  .expand-submenu {
    $size: 3em;
    position : relative;
    right : 0;
    width : $size;
    height : $size;
    cursor: pointer;
    @include hideFrom($bp);
    transition: all .3s;

    &::after {
      content: "";
      position : absolute;
      width : 40%;
      height : 40%;
      top : 20%;
      left : 30%;
      border-left : ($size * 0.15) solid #666;
      border-bottom : ($size * 0.15) solid #666;
      border-radius : ($size * 0.08);
      transform: rotate(-45deg);
      transition: all .3s;
    }

    &.active {
      transform: rotate(180deg);
    }
  }

  // incluir el estilo del menu si se ha definido
  @if $style == default { @include menuStyleDefault  }
  @if $style == nav     { @include menuStyleNav($bp) }
  @if $style == button  { @include menuSyleButton    }

  // compilar los mixins en modo CSS
  @if $cssCore == true {
    &.default    { @include menuStyleDefault  }
    &.nav-bar    { @include menuStyleNav($bp) }
    &.button-bar { @include menuSyleButton    }
  }
}

// mixin Nav (para el contenedor del menú)
@mixin edNav($direction, $bp){
  @include to($bp) {
    $lrWidth : 85%;
    position   : fixed;
    transition : all .3s;
    height     : 100vh;
    z-index    : 200;

    @if $direction == left or $direction == right {
      width : $lrWidth;
    } @else {
      width : 100%;
    }

    @if $direction == left {
      left : $lrWidth * -1;

      &.show-menu { left : 0 }
    }

    @if $direction == right {
      right : $lrWidth * -1;

      &.show-menu { right : 0 }
    }

    @if $direction == top {
      top : -100%;

      &.show-menu { top : 0 }
    }

    @if $direction == bottom {
      bottom : -100%;

      &.show-menu { bottom : 0 }
    }
  }
}


@mixin navToggle($size, $color) {
  $segmentHeight : ($size / 8);
  position       : relative;
  z-index        : 300;
  width          : $size * 1.3;
  height         : $size;
  box-shadow     : 0 ($segmentHeight * 2) $color inset;
  cursor         : pointer;

  &::after,
  &::before {
    content          : "";
    position         : absolute;
    width            : 100%;
    height           : ($segmentHeight * 2);
    background-color : $color;
    bottom           : 0;
  }

  &::after {
    bottom : $segmentHeight * 3;
  }
}

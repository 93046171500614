$height-title: rem(250px);
.section-title{
  height: $height-title;
  text-align: right;
  font-size: 3em;
  padding-right: 4rem;
  padding-top: 1rem;
  background: #222;
  color: $second-color;
  @include to(m){
    padding-right: 1rem;
  }
}
.section-content{
  background: $main-color;
  padding: 2rem;
  position: relative;
  top: ($height-title / 2)* -1;
  @include to(m){
    max-width: 90%;
    margin: 0 auto;
  }
  h3{
    text-align: center;
    text-transform: uppercase;
    color: $second-color;
    margin-bottom: $gutter;
  }
  ul, ol{
    color: $second-color;
    @extend %list-style;
    max-width: 90%;
    margin: 0 auto;
    @include from(l){
      max-width: 70%;
    }
    li{
      margin-bottom: $gutter * .5;
      position: relative;
      text-align: center;
      padding: 5px;

    }
  }
  p{
    color: $second-color;
    text-align: center;
    margin-bottom: $gutter;
  }
}

.contacto{
  &__container{
    @include edContainer;

  }
  &__datos{
    @include edItem(100,50);
    h3, h4, p{
      text-align: right;
    }
    h3{
      font-size: 3em;
    }
    h4{
      font-size: 1.5em;
    }
    .p-group{
      display: flex;
      justify-content: flex-end;
      margin-bottom: $gutter*.5;
      &:first-of-type{
        margin-top: $gutter;
      }
    }
    span{
      padding-left: 8px;
    }
    [class^="icon-"]:before{
      position: relative;
      top: 2px;
    }
    .icon-map::before{
      top: 5px;
    }
    a{
      color: inherit;
      &:hover{
        text-decoration: underline;
      }
    }
  }
  .mapa a{
    display: block;
    box-shadow: 0 0 0 2px rgba($main-color,.5);
    transition: $transition;
    position: relative;
    &::after{
      content: '\72';
      font-family: "boinas-negras" !important;
      position: absolute;
      height: 100%;
      width: 100%;
      top:0;
      left: 0;
      background: rgba(#000,.75);
      opacity: 0;
      transition: $transition;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.5em;
      color: #fff;
    }
    &:hover{
      box-shadow: 0 0 0 2px rgba($main-color,1);
      &:after{
        opacity: 1;
      }
    }
  }
  &__form{
    @include edItem(100,50);
    margin-top: 2em;
    @include from(m){
      margin-top: $gutter;
      padding-left: $gutter;
      padding-right: $gutter;
    }
    .select-group{
      position: relative;
      background: $second-color;
      label{
        position: absolute;
        top: 0;
        right: 0;
        //background: #000;
        z-index: 100;
        width: rem(30px);
        height: 100%;
        font-size: 1.5em;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      select{
        display: block;
        height: rem(34px);
        width: 100%;
        appearance: none;
        border: border($menu-bg, 1px);
        padding-left: em(7px);
        margin-bottom: $gutter*2;
        position: relative;
        background: transparent;
        z-index: 500;
        &:focus{
          outline: none;
          border: border($menu-bg, 1px);
        }
      }
    }

  }
}
.form{
  $height: 2rem;
  $border-form: 1px solid rgba($main-color,1);
  margin-top: $gutter;
  padding: $gutter;
  padding-top: $gutter * 2;
  background: rgba($second-color,.25);
  border: $border-form;
  .input-group, .input-group-textarea{
    position: relative;
    margin-bottom:$gutter*2.5;
    span{
      display: none;
    }
    label, input{
      height: $height;
    }
    label{
      position: absolute;
      left: 5px;
      top: 5px;
      transition: $transition;
      color: $main-color;
    }
    input, textarea{
      background: transparent;
      width: 100%;
      border: none;
      outline: none;
      resize: none;
      border-bottom: 1px solid $main-color;
      font-family: $main-font;
      font-size: 1em;
      &:focus{
        outline: none;
        & + label{
          top: -1.5em;
          font-size: .85em;
          left: 0;
        }
      }
    }
    textarea{
      transition: $transition;
      min-height: $height;
      & + label{
        top: 7px;
      }
      &:focus{
        min-height: $height * 2;
      }
    }

  }
  .input-group-textarea{
    margin-bottom: $gutter;
  }
  .input-group.lleno{
    label{
      top: -1.5em;
      font-size: .85em;
      left: 0;
    }
  }
  .input-group-textarea.lleno{
    label{
      top: -1.5em;
      font-size: .85em;
      left: 0;
    }
    textarea{
      min-height: $height * 2;
    }
  }
}
.form-footer{
  display: flex;
  .boton{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 25%;
  }
  .messages{
    width: 75%;
  }
  .btn{
    @include button($main-color);
    color: $second-color;
    text-transform: uppercase;
    @include to(m){
      padding: 0 10px;
    }
  }
}
.success, .error{
  padding: rem(5px);
  color: rgba($second-color,.5);
  display: none;
  text-align: center;
}
.success{
  background: darkgreen;
  //display: block;
}
.error{
  background: darkred;
}
.radio-group{
  margin-top: $gutter;
  margin-bottom: $gutter*2;
  display: flex;
  justify-content: space-around;
  .radios{
    display: flex;
    justify-content: space-around;
    width: 60%;
    margin: 0 auto;
    padding-top: 1px;
    input{
      outline: none;
      &:focus{
        outline: none;
      }
    }
  }
  p{
    color: $main-color;
  }

}
#form-reclutamiento, #form-cotizacion{
  display: none;
}

.network{
  display: flex;
  justify-content: flex-end;
  margin-bottom: $gutter;
  a{
    //display: block;
    width: rem(50px);
    background: transparent;
    //box-shadow: 0 0 0 2px $main-color;
    color: $headers-color;
    margin-right: $gutter;
    font-size: 1.7em;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    padding: 5px;
    transition: $transition;
    &:nth-child(3){
      margin-right:0;
    }
    &:hover{
      background: rgba($main-color,.95);
      text-decoration: none;
      color: $second-color;
    }
  }
}
.icon-load-b{
  animation: girar 3s linear infinite;
  display: inline-block;
  position: relative;
  color: yellow;
  font-size: .5em;
  //transform: translateX(500px) translateY(500px);
}
@keyframes girar {
  to {
    transform: rotate(360deg);
    transform-origin: top;
  }
}
.fa-spinner:before{
  padding: 0 1.5em;
}
.accestomail{
  margin-top: $gutter;
  float: right;
}